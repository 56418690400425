<template>
    <div class="col-sm-12" id="politicaDePrivacidade">
        <br>
        <br>
        <br>
        <br>
        <br>
        <h2 class="destaque">POLÍTICA DE PRIVACIDADE EAGLE TRACK</h2>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">INTRODUÇÃO</h4>
            <p>
                A Política de Privacidade da Eagle Track foi criada com o intuito de conferir e 
                assegurar a privacidade das informações dos usuários. Esta Política se aplica a 
                indivíduos que interagem com nossos serviços, tanto físicos quanto online, 
                referentes às páginas públicas da Eagle Track e aplicativos mobile. 
                O que você precisa saber antes de ler nossa política:
            <br>
                <b class="destaque">Dados Pessoais</b>
                são todas as informações que permitem identificação. Como por exemplo,
                nome, CPF, e-mail, telefone, entre outros. 
            <br>
                <b class="destaque">Dados Pessoais Sensíveis</b>
                são dados pessoais relacionados à origem racial ou étnica, convicção religiosa, 
                opinião política, filiação a sindicato ou a organização de caráter religioso, 
                filosófico ou político, dado referente à saúde ou à vida sexual, dado genético 
                ou biométrico, quando vinculados ao usuário. 
            <br>
                <b class="destaque">Tratamento de Dados Pessoais</b>
                significa qualquer operação, como a coleta, produção, recepção, classificação, 
                utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, 
                armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, 
                transferência, difusão ou extração, realizada com dados pessoais. 
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">OBJETIVO</h4>
            <p>
                O presente documento tem como propósito atender os requisitos da 
                Lei Geral de Proteção de Dados – Lei 13.709/2018 e demais legislações, normas que sirvam 
                para manter a privacidade e o tratamento dos dados pessoais, bem como, garantir ao usuário, 
                que tenha conhecimento de como será realizado o tratamento de dados pessoal pela Eagle Track, 
                na qualidade controladora e operadora, nos termos da Lei Geral de Proteção de Dados Pessoais. 
                A Eagle Track irá realizar o tratamento dos dados pessoais observando os princípios de 
                <b class="destaque">
                    boa-fé, da necessidade, da finalidade, do livre acesso, da transparência, 
                    da segurança e prevenção dos dados.
                </b>
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">DIREITOS DO USUÁRIO</h4>
            <p>
                A Lei Geral de Proteção de dados tem por objetivo proteger os direitos fundamentais de 
                liberdade e privacidade do usuário, dessa forma, a Eagle Track garante estar comprometida 
                com a nova Lei Geral de Proteção de Dados (LGPD). Ressaltamos <b class="destaque">
                    que o cliente é livre para concordar ou não com nossas políticas
                </b>, sendo possível optar pelo não compartilhamento de alguns dados opcionais, mas que 
                podem interferir ou impedir o uso de alguns dos benefícios ou funcionalidades disponibilizadas. 
                Além disso, o cliente (titular dos dados) tem direito de solicitar informações sobre o tratamento
                de dados realizado, além de outros como: acesso aos dados compartilhados, correção, adequação e 
                complementação dos dados, direito de revogação do consentimento, exclusão dos dados e/ou anonimização.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">COLETA DE DADOS</h4>
            <p>
                Solicitamos informações pessoais apenas
                <b class="destaque">quando realmente precisamos delas</b>
                para o fornecimento de um serviço, 
                estes, por meios justos e legais, com o conhecimento e consentimento do usuário.
                Nossa plataforma coleta dados de acordo com o disposto nesta seção: 
                <br>
                <b class="destaque">Dados pessoais e/ou informações de contato:</b>
                informações fornecidas pelo usuário como: nome completo, CPF, data 
                de nascimento, RG, email, celular, etc.
                <br>
                <b class="destaque">Dados de localização:</b>
                informações extraídas e fornecidas pelo usuário como: cidade, CEP, bairro, rua, número, 
                complemento, ponto de referência, latitude e longitude.
                <br>
                <b class="destaque">Dados sensíveis:</b>Não serão coletados dados sensíveis de nossos usuários.
                <br>
                <b class="destaque">Dados de login:</b>
                Informações para acesso dos nossos serviços, como: email e senha.
                <br>
                <b class="destaque">Dados de uso e técnicas sobre o dispositivo utilizado: </b>
                Informações sobre o dispositivo que o usuário utiliza para 
                acessar nossos serviços como: IP, tipo de sistema operacional, versão do navegador, 
                data e hora do acesso.
                <br>
                <b class="destaque">Cookies: </b> Em nossa plataforma, só usamos cookies se forem necessários 
                para uma aplicação ou serviço que estamos fornecendo e/ou para identificarmos quais são os 
                assuntos de seu interesse. Importante destacarmos que, se você quiser optar por não utilizar 
                as vantagens destes cookies, acesse “Configurações” > “Cookies” em seu navegador para 
                desativá-los e, consequentemente, seu processo de navegação não será rastreado. 
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">TRATAMENTO DOS DADOS </h4>
            <p>
                <b class="destaque">Consentimento: </b> Como já manifestado, determinadas operações de tratamento de 
                dados pessoais realizadas em nosso site dependerão da prévia concordância do usuário, que deverá
                <b class="destaque">manifesta-la de forma livre, informada e inequívoca. </b>O usuário poderá revogar 
                o seu consentimento a qualquer momento, sendo que, não havendo hipótese legal que permita ou que 
                demande o armazenamento dos dados, os dados fornecidos mediante consentimento serão excluídos.
                <br>
                <b class="destaque">Obrigação legal: </b> A Eagle Track necessita gerar documentos fiscais referentes 
                às vendas de produtos/soluções através da plataforma. A partir disso, alguns dados pessoais
                fundamentais para essa Obrigação Legal não poderão ser excluídos da plataforma em caso de 
                revogação de consentimento. 
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">FINALIDADE DO USO DOS DADOS </h4>
            <p>
                Ofertar para os clientes a adequada prestação dos serviços disponibilizados 
                pela Eagle Track, com o intuito de entregar as soluções e produtos adquiridos 
                pelo usuário. Como já mencionado, alguns serviços coletam dados denominados 
                como dados de localização, para as funções de rastreamento. Em nossos serviços, 
                através do GPS coletamos a localização do usuário e utilizamos a API do Google 
                Maps para orientação do mesmo.
                <br>
                Além disso, a Eagle Track utiliza os dados coletados em seus serviços e de terceiros de 
                acordo com o disposto abaixo:
                <br>
                a) Os dados coletados em virtude do acesso a plataforma web e aplicativos mobile 
                (Eagle Finder, Eagle Checkpoint, Eagle Driver) 
                têm como finalidade garantir a funcionalidade e qualidade dos serviços prestados pela Eagle Track.
                <br>
                b) Os dados coletados em virtude aos formulários preenchidos em nossos sites eagletrack.com.br e
                store.eagletrack.com, têm como finalidade a oferta e venda de novos produtos, serviços e promoções 
                bem como a identificação de assuntos de interesse.
                <br>
                c) A coleta de dados pessoais com finalidade comercial, cadastradas na ferramenta Pipedrive, para 
                interação com o cliente e controle do fluxo de vendas referente aos produtos, serviços e promoções.
                <br>
                d) A coleta de dados pessoais para controle financeiro, cadastradas na ferramenta Omie, para fins de 
                emissão de cobrança e de documentos fiscais. 
                <br>
                e) Entre outros como: Cadastros internos, cadastros na plataforma em prol do funcionamento da 
                ferramenta, realização de campanhas de marketing dos produtos que a Eagle Track tem a oferecer, 
                registro de efetivação de consentimento do titular dos dados, informações para entrega de produtos
                no endereço do usuário, etc.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">COMPARTILHAMENTO E TRATAMENTO DE INFORMAÇÕES PESSOAIS</h4>
            <p>
                O usuário ao aceitar esta política, consente livre e expressamente que seus dados pessoais 
                poderão ser compartilhados internamente de acordo com sua finalidade, autorizando a Controladora
                a tomar decisões e a realizar o tratamento dos dados pessoais do Titular com os departamentos 
                operacionais, financeiros, jurídicos e comerciais, ou seja, com a intenção de prestar todos 
                os serviços ofertados da melhor forma. 
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">ARMAZENAMENTO</h4>
            <p>
                Os dados pessoais estão armazenados em um servidor em nuvem o qual possui backup com atualização 
                diária. E, além disso, possui inúmeros requisitos de segurança e certificação digital onde somente 
                o responsável pela Infraestrutura (TI) possui acesso aos backups.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">TEMPO DE ARMAZENAMENTO DOS DADOS PESSOAIS</h4>
            <p>
                Os dados pessoais coletados serão armazenados pelo período de cinco anos. Vale tornar explícito que, 
                se houver alguma justificativa legal ou regulatória, os dados poderão continuar armazenados ainda que 
                a finalidade para a qual fosse coletados ou tenham sido tratados tenha se exaurido. Uma vez finalizado
                o tratamento, observadas as disposições desta seção, os dados são apagados ou anonimizados. 
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">COMPROMISSO DO USUÁRIO</h4>
            <p>
                O usuário se compromete a fazer uso adequado dos conteúdos e da informação que a Eagle Track oferece 
                no site e com caráter enunciativo, mas não limitativo:
                <br>
                <ul>
                    <li>
                        Não se envolver em atividades que sejam ilegais ou contrárias à boa fé e à ordem pública;
                    </li>
                    <li>
                        Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, casas de apostas legais, 
                        jogos de sorte e azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou 
                        contra os direitos humanos; 
                    </li>
                    <li>
                        Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do site, de seus 
                        fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer 
                        outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente 
                        mencionados. 
                    </li>
                </ul>
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">DISPOSIÇÕES FINAIS</h4>
            <p>
                Eventualmente a Eagle Track poderá alterar estas políticas, para tanto, o cliente será comunicado 
                através do seu e-mail cadastrado e estará disponível no site oficial. A presente versão desta 
                Política de Privacidade foi atualizada pela última vez em: 28 de junho de 2021.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">CONTATO</h4>
            <p>
                Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que
                tratamos, entre em contato com o Encarregado de Proteção de Dados Pessoais, pelo 
                e-mail: dpo@eagletrack.com.br.
            </p>
        </div>
    </div>
</template>
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-100341043-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());  gtag('config', 'UA-100341043-1');
</script>
<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    
})
</script>
<style lang="scss">
    #politicaDePrivacidade{
        padding-left: 5vw;
        padding-right: 5vw;
        font-family: nexabook;
        .paragrafo{
            text-align: justify;
        }
        .destaque{
            font-family: nexabold;
        }
    }
</style>